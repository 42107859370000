import { styled } from "@linaria/react"
import { Row } from "react-bootstrap"

import { BORDER_COLOR, TEXT_COLOR } from "src/features/SupportingMaterialsVault/constants"

export const CardHeaderContainer = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${BORDER_COLOR};
  cursor: pointer;
`

export const CardTitleContainer = styled(Row)`
  margin: 0;
  justify-content: space-between;
  color: ${TEXT_COLOR};
  font-size: 14px;
  font-weight: 300;

  .icon-rotated {
    transform: rotate(180deg);
  }
`

export const CardTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
  padding-left: 7px;

  &:hover {
    text-decoration-line: underline;
    text-decoration-skip-ink: none;
    text-underline-position: from-font;
  }
`

export const Badge = styled.span`
  border-radius: 100px;
  background: ${BORDER_COLOR};
  color: #7e90a9;
  padding: 2px 5px;
  font-size: 10px;
  margin-right: 5px;
`

export const AlertContainer = styled.div`
  margin-top: 10px;
  padding-left: 25px;
`
