import { styled } from "@linaria/react"

import { TEXT_COLOR } from "src/features/SupportingMaterialsVault/constants"

export const DocumentFieldTitle = styled.div`
  color: #46404f;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 5px;
`

export const DocumentFieldValue = styled.div`
  color: ${TEXT_COLOR};
  font-size: 14px;
  font-weight: 300;
`
