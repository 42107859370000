import { styled } from "@linaria/react"

import { FONT_WEIGHTS } from "src/styles/sizes"
import { DRAWER_BORDER_COLOR } from "src/features/SupportingMaterialsVault/drawerStyles"
import { TEXT_COLOR } from "src/features/SupportingMaterialsVault/constants"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-top: 1px solid ${DRAWER_BORDER_COLOR};
  padding: 15px;
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const Name = styled.div`
  font-family: "Space Grotesk", sans-serif;
  color: ${TEXT_COLOR};
  font-size: 14px;
  font-weight: ${FONT_WEIGHTS.bold};
`

export const Value = styled.div`
  color: ${TEXT_COLOR};
  font-size: 14px;
  font-weight: ${FONT_WEIGHTS.semiBold};
`
