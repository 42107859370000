import { Card } from "react-bootstrap"
import { styled } from "@linaria/react"

import { BORDER_COLOR } from "src/features/SupportingMaterialsVault/constants"

export const ItemCardContainer = styled(Card)`
  border-radius: 8px;
  border: 1px solid ${BORDER_COLOR};
  box-shadow: none;
  padding: 0;
`

export const DocumentsList = styled.div`
  padding: 15px 40px;
`
