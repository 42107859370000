import { styled } from "@linaria/react"

import { ContentContainer } from "src/styles/components/ContentComponents"
import { BREAKPOINTS } from "src/styles/sizes"

export const Container = styled.div`
  height: auto;
`

export const ScrollContainer = styled.div`
  height: calc(100vh - 96px);
  overflow: auto;
`

export const ItemsListContainer = styled(ContentContainer)`
  @media (min-width: ${BREAKPOINTS.medium}) {
    max-width: 1000px;
  }
`
