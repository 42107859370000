import { styled } from "@linaria/react"

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  height: 100%;
`

export const DocumentCardContainer = styled.div`
  padding: 15px 15px 0;
`
