import { styled } from "@linaria/react"
import { Image } from "react-bootstrap"

import { FONT_WEIGHTS } from "src/styles/sizes"
import { BRAND_COLOR, TEXT_COLOR } from "src/features/SupportingMaterialsVault/constants"

export const AlertContainer = styled.div`
  display: flex;
  padding: 12px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(243, 121, 113, 0.15);
  margin-bottom: 10px;

  color: ${TEXT_COLOR};
  font-size: 14px;
  font-style: normal;
  font-weight: ${FONT_WEIGHTS.bold};
  line-height: 130%;
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const Icon = styled(Image)`
  width: 20px;
  height: 20px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 8px;
`

export const PrimaryButton = styled.button`
  height: 28px;
  padding: 8px 12px;
  color: white;
  background-color: ${BRAND_COLOR};
  border-radius: 6px;
  border: 1px solid ${BRAND_COLOR};
  font-family: "Space Grotesk", sans-serif;
  font-size: 14px;
  font-weight: ${FONT_WEIGHTS.semiBold};
  margin: 0;

  &:hover,
  &:focus {
    color: white;
  }

  &:disabled {
    background-color: #cfaaed;
    border-color: #cfaaed;
  }
`

export const SecondaryButton = styled(PrimaryButton)`
  color: ${BRAND_COLOR};
  background-color: white;

  &:hover,
  &:focus {
    color: ${BRAND_COLOR};
  }
`

export const SelectContainer = styled.div`
  max-width: 360px;
  margin-bottom: 8px;

  svg {
    fill: ${TEXT_COLOR};
  }
`

export const selectStyles = {
  control: (styles) => ({
    ...styles,
    padding: "0 12px",
    borderRadius: "8px",
    height: "44px",
    border: "1px solid #E7ECF3"
  }),
  placeholder: (styles) => ({
    ...styles,
    color: TEXT_COLOR,
    fontWeight: FONT_WEIGHTS.normal
  })
}
