import { styled } from "@linaria/react"

import { BORDER_COLOR } from "src/features/SupportingMaterialsVault/constants"

export const LoadingDocumentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${BORDER_COLOR};
  padding: 15px 20px;
  margin-bottom: 10px;
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 11px;
  line-height: 16px;
  color: #3f3f46;
  font-family: "Space Grotesk";
`

export const FileName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 14px;
  font-weight: 500;
`

export const FileNameContainer = styled.div`
  display: flex;
  width: 80%;

  .extension-icon {
    min-height: 16px;
    min-width: 16px;
    background-size: 16px 16px;
    margin: 0 6px 0px 0;
  }
`
