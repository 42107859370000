import { styled } from "@linaria/react"

import {
  BORDER_COLOR,
  BRAND_COLOR
} from "src/features/SupportingMaterialsVault/constants"
import {
  Title,
  DRAWER_BORDER_COLOR
} from "src/features/SupportingMaterialsVault/drawerStyles"

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-bottom: 1px solid ${DRAWER_BORDER_COLOR};
  box-shadow: 0 12px 24px -2px rgba(215, 222, 232, 0.2);
  width: 100%;
  padding: 10px 15px 0 15px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`

export const Tab = styled(Title)`
  padding: 0 4px 16px 4px;
  font-size: 16px;
  cursor: pointer;

  &.current {
    color: ${BRAND_COLOR};
    border-bottom: 3px solid ${BRAND_COLOR};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  overflow-y: auto;
  padding: 0 15px 15px 15px;
  height: 100%;
`

export const DocumentCardContainer = styled.div`
  display: flex;
  width: 306px;
  padding: 15px 20px 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;

  border-radius: 8px;
  border: 1px solid ${BORDER_COLOR};
`

export const DocumentFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 8px;
`

export const DocumentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
