import { styled } from "@linaria/react"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
`

export const ContentContainer = styled.div`
  &:not(.view-content-container--fullscreen) {
    height: auto;
    position: relative;
  }
`
