import React from "react"
import { styled } from "@linaria/react"
import classNames from "classnames"
import { oneOf, shape, string, number } from "prop-types"

import { getInitials } from "src/helpers/user"

import AvatarImageContainer from "./AvatarImageContainer"
import { AvatarImage, UserInitials } from "./styles"

const UserAvatar = ({ user, className, size = "", variant = "" }) => {
  const { avatarColorId, avatarUrl, initials } = user || {}
  const showStub = user && !avatarUrl && (avatarColorId || avatarColorId === 0)
  const stubClasses = classNames("round-toggle", "initials", "avatar-image", {
    [`avatar-stub avatar-modifier-${avatarColorId || 0}`]: showStub
  })

  return (
    <div className={className} data-testid={`user-avatar-${user.id}`}>
      {avatarUrl ? (
        <AvatarImageContainer size={size} variant={variant}>
          <AvatarImage src={avatarUrl} />
        </AvatarImageContainer>
      ) : (
        <div className={size}>
          <UserInitials variant={variant} className={stubClasses}>
            {initials || getInitials(user)}
          </UserInitials>
        </div>
      )}
    </div>
  )
}

UserAvatar.propTypes = {
  user: shape({
    id: number,
    initials: string,
    avatarColorId: number,
    avatarUrl: string
  }),
  size: oneOf(["", "large"]),
  variant: oneOf(["", "removed", "added"]),
  className: string
}

export default styled(UserAvatar)``
