import { styled } from "@linaria/react"

export const LinkLabel = styled.span`
  &:hover {
    color: #9647d7;
    text-decoration: underline;
  }
`

export const Link = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  color: #9647d7;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
  position: relative;
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/-1;

  &.deleted {
    cursor: not-allowed;
  }

  &.deleted ${LinkLabel}:hover {
    text-decoration: none;
  }
`
