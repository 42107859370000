import { styled } from "@linaria/react"
import { Image } from "react-bootstrap"

export const Container = styled.div`
  font-size: 12px;
  font-weight: 300;
  display: flex;
`

export const Icon = styled(Image)`
  width: 16px;
  height: 16px;

  &.variant-right-to-text {
    margin-left: 6px;
  }

  &.variant-left-to-text {
    margin-top: 2px;
    margin-right: 6px;
  }
`
