import { styled } from "@linaria/react"

import { BASE_TEXT_COLOR } from "src/features/UniversalAi/Sidebar/styles"

export const ReferenceContainer = styled.span`
  display: inline-block;
  text-align: right;
`

export const StyledReference = styled.sup`
  position: relative;
  top: 0;
  background-color: #e9e9ea;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 4px;
  color: ${BASE_TEXT_COLOR};
  font-size: 11px;
  font-weight: 700;
  line-height: 1.2;
  cursor: pointer;
`
