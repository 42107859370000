import React, { useRef, useState, useEffect } from "react"
import classNames from "classnames"
import truncate from "lodash/truncate"
import { styled } from "@linaria/react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { oneOf, oneOfType, elementType, string, number, bool } from "prop-types"

import { recogniseLink } from "src/helpers/link"

const isEllipsisActive = (e) => e.offsetWidth < e.scrollWidth

const TextWithTooltip = ({
  component: Component = "span",
  text,
  className,
  placement = "bottom",
  maxLength = 40,
  recogniseLinks
}) => {
  const ref = useRef()
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    const element = ref.current
    if (!element) return

    setIsTruncated(isEllipsisActive(element))
  }, [ref.current, setIsTruncated])

  if (!text?.length) return null

  const forceTruncate = maxLength && text.length > maxLength
  const truncatedText = forceTruncate ? truncate(text, { length: maxLength }) : text
  const showTooltip = isTruncated || forceTruncate

  return (
    <OverlayTrigger
      delay={300}
      placement={placement}
      overlay={
        <Tooltip
          className={classNames({ "d-none": !showTooltip })}
          id={`tooltip-${placement}`}
        >
          {text}
        </Tooltip>
      }
    >
      <Component ref={ref} className={className}>
        {recogniseLinks ? recogniseLink({ text, truncatedText }) : truncatedText}
      </Component>
    </OverlayTrigger>
  )
}

TextWithTooltip.propTypes = {
  component: oneOfType([elementType, string]),
  placement: oneOf(["top", "right", "bottom", "left"]),
  recogniseLinks: bool,
  maxLength: number,
  text: string
}

export default styled(TextWithTooltip)`
  display: block;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
