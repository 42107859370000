import { styled } from "@linaria/react"

export const FileName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 14px;
  font-weight: 500;
`

export const Container = styled.div`
  display: flex;
  cursor: pointer;
  width: 90%;

  .extension-icon {
    min-height: 16px;
    min-width: 16px;
    background-size: 16px 16px;
    margin: 0 6px 0px 0;
  }
`
