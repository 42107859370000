import { styled } from "@linaria/react"

import { FONT_WEIGHTS } from "src/styles/sizes"
import { Title as DefaultTitle } from "src/features/SupportingMaterialsVault/drawerStyles"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 10px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Title = styled(DefaultTitle)`
  color: #46404f;
`

export const Text = styled(Title)`
  font-weight: ${FONT_WEIGHTS.semiBold};
`
